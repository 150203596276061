<template>
  <div class="ins-index">
    <div class="banner"></div>
    <HeaderNav active="/industry/index"></HeaderNav>
    <router-view />
    <Footer></Footer>
  </div>
</template>

<script>
import HeaderNav from "@/components/HeaderNav.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "IndustryIndex",
  components: {
    HeaderNav,
    Footer,
  },
};
</script>

<style lang="less">
.ins-index {
  width: 100%;
  height: 200px;
  .banner {
    background-image: url(../../assets/banner-hangye.jpg);
    height: 200px;
  }
}
</style>